import {
    Button,
    Checkbox,
    Flex,
    FormControl,
    Input,
    useToast,
    VStack,
    Text,
    useDisclosure,
    Spacer,
    Stack,
} from "@chakra-ui/react";
import { Label, FieldError, InputJson, FieldSet, DeleteStoreAlertDialog } from "../../components";
import { UpdateStoreRequest, Store, UpdateStoreFormData, StoreModes } from "../../types";
import { PageContainer } from "../PageContainer";
import {
    useEnqueueDeploymentMutation,
    useStoreMutation,
    generateStoreQueryKey,
    useEnqueuePurgeCacheMutation,
} from "../../hooks";
import { useForm } from "react-hook-form";
import { toJSON, withDeleteStoreConfirmationModal, formatDateTimeDisplay } from "../../lib";
import { useQueryClient } from "@tanstack/react-query";
import { ROUTES } from "../../Routes";
import { DnsSetup } from "../../components/DnsSetup/DnsSetup";
import { useState } from "react";

type Props = {
    store: Store;
};

export const EditStoreTab = ({ store }: Props) => {
    const defaultValues: UpdateStoreFormData = buildDefaultValues(store);
    const {
        handleSubmit,
        register,
        setValue,
        formState: { errors },
        watch,
    } = useForm<UpdateStoreFormData>({
        defaultValues,
    });
    const showToast = useToast({
        duration: 5000,
        isClosable: true,
        variant: "subtle",
    });
    const enqueueDeployment = useEnqueueDeploymentMutation({ polling: false });
    const queryClient = useQueryClient();
    const storeMutation = useStoreMutation(store.id, store.customerUrl);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const enqueuePurgeCacheMutation = useEnqueuePurgeCacheMutation();

    const [dnsSetup, setDnsSetup] = useState({
        isOpen: false,
        errorMessage: "",
    });

    let watchLogLevel = watch("logLevel");
    const handleLogLevelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            setValue("logLevel", "debug");
            watchLogLevel = watch("logLevel");
        } else {
            setValue("logLevel", "error");
            watchLogLevel = watch("logLevel");
        }
    };

    let watchUtmParameters = watch("ignoreUtmParameters");
    const handleUtmParameterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            setValue("ignoreUtmParameters", true);
            watchUtmParameters = watch("ignoreUtmParameters");
        } else {
            setValue("ignoreUtmParameters", false);
            watchUtmParameters = watch("ignoreUtmParameters");
        }
    };

    let watchInternalRumEventsEnabled = watch("internalRumEventsEnabled");
    const handleWebVitalsAnalyticsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            setValue("internalRumEventsEnabled", true);
            watchUtmParameters = watch("internalRumEventsEnabled");
        } else {
            setValue("internalRumEventsEnabled", false);
            watchUtmParameters = watch("internalRumEventsEnabled");
        }
    };

    const handleUpdateStore = (formData: UpdateStoreFormData) => {
        const onError = () => {
            showToast({
                title: "Error",
                description: "Unable to parse JSON. Please fix the syntax and try again.",
                status: "error",
            });
        };

        !formData.currencyMap && (formData.currencyMap = "{}");
        const orderSyncCursor =
            formData.orderSyncCursor && formData.orderSyncCursor !== "" ? formData.orderSyncCursor : null;

        let staticSiteEnabledAt;
        if (formData.staticSiteEnabled) {
            staticSiteEnabledAt = store.staticSiteEnabledAt ? store.staticSiteEnabledAt : new Date().toISOString();
        } else if (!formData.staticSiteEnabled) {
            staticSiteEnabledAt = null;
        }

        const requestBody: UpdateStoreRequest = {
            name: formData.name,
            customerUrl: formData.customerUrl,
            ecomUrl: formData.ecomUrl,
            currencyDefault: formData.currencyDefault,
            orderSyncEnabled: formData.orderSyncEnabled,
            orderSyncCursor: orderSyncCursor,
            edgeMemberEnabled: formData.edgeMemberEnabled,
            currencyMap: toJSON(formData.currencyMap, { onError }),
            zoneId: formData.zoneId,
            countryDefault: formData.countryDefault !== "" ? formData.countryDefault : null,
            logLevel: formData.logLevel,
            ignoreUtmParameters: formData.ignoreUtmParameters,
            staticSiteEnabled: formData.staticSiteEnabled,
            staticSiteEnabledAt: staticSiteEnabledAt,
            internalRumEventsEnabled: formData.internalRumEventsEnabled,
            localCacheEnabled: formData.localCacheEnabled,
        };

        storeMutation.mutate(requestBody, {
            onSuccess: () => {
                // Clear entire store cache if local caching is disabled
                const localCacheToggled = store.localCacheEnabled !== requestBody.localCacheEnabled;
                if (localCacheToggled && requestBody.localCacheEnabled === false) {
                    enqueuePurgeCacheMutation.mutate({ customerUrl: requestBody.customerUrl! });
                }

                enqueueDeployment.mutate(requestBody.customerUrl!);
                queryClient.invalidateQueries(generateStoreQueryKey(store.customerUrl));
                showToast({
                    title: "Success",
                    description: "Store successfully updated.",
                    status: "success",
                });
            },
            onError: () => {
                showToast({
                    title: "Error: Failed to update store",
                    description: "An error has occurred. Please wait a few moments and try again.",
                    status: "error",
                });
            },
        });
    };

    return (
        <PageContainer padding={{ base: 0, md: "spacer-7" }}>
            <FormControl maxWidth={{ md: "100%" }}>
                <form onSubmit={handleSubmit(handleUpdateStore)}>
                    <FieldSet legend="Store Information" storeId={store.id}>
                        <Label htmlFor="name">Name</Label>
                        <Input
                            width="100%"
                            placeholder="Store Name"
                            type="text"
                            id="name"
                            {...register("name", {
                                required: {
                                    value: true,
                                    message: "Store name is required",
                                },
                            })}
                        />
                        <FieldError>{errors.name?.message}</FieldError>

                        <Label htmlFor="customerUrl">Customer URL</Label>
                        <Input
                            width="100%"
                            placeholder="Customer URL"
                            type="text"
                            id="customerUrl"
                            {...register("customerUrl", {
                                required: {
                                    value: true,
                                    message: "Customer URL is required",
                                },
                            })}
                        />
                        <FieldError>{errors.customerUrl?.message}</FieldError>

                        <Label htmlFor="ecomUrl">Ecom URL</Label>
                        <Input
                            width="100%"
                            placeholder="Ecom URL"
                            type="text"
                            id="ecomUrl"
                            {...register("ecomUrl", {
                                required: {
                                    value: true,
                                    message: "Ecom URL is required",
                                },
                            })}
                        />
                        <FieldError>{errors.ecomUrl?.message}</FieldError>
                        <Label htmlFor="zoneId">Zone ID</Label>
                        <Input width="100%" placeholder="Zone ID" type="text" id="zoneId" {...register("zoneId")} />
                        <FieldError>{errors.zoneId?.message}</FieldError>
                        <Label htmlFor="countryDefault">Country Default</Label>
                        <Input
                            width="100%"
                            placeholder="Country"
                            type="text"
                            id="countryDefault"
                            {...register("countryDefault")}
                        />
                        {store.workerPlatform === "shopify" && store.shopifyAuthToken ? (
                            <>
                                <FieldError></FieldError>
                                <Label htmlFor="shopifyTimeZone">Shopify Time Zone</Label>
                                <Input
                                    width="100%"
                                    type="text"
                                    id="shopifyTimeZone"
                                    value={
                                        store.shopifyTimeZone
                                            ? `${store.shopifyTimeZone.timezoneAbbreviation ?? ""} - ${
                                                  store.shopifyTimeZone.ianaTimezone ?? ""
                                              }`
                                            : "Not set"
                                    }
                                    isReadOnly
                                />
                            </>
                        ) : null}
                        {store.txtRecordHostname || store.txtRecordValue ? (
                            <>
                                <FieldError></FieldError>
                                <Label htmlFor="txtRecordHostname">TXT Record Host Name</Label>
                                <Input
                                    width="100%"
                                    type="text"
                                    id="txtRecordHostname"
                                    isReadOnly
                                    value={store.txtRecordHostname ?? ""}
                                />
                                <FieldError></FieldError>
                                <Label htmlFor="txtRecordValue">TXT Record Value</Label>
                                <Input
                                    width="100%"
                                    type="text"
                                    id="txtRecordValue"
                                    isReadOnly
                                    value={store.txtRecordValue ?? ""}
                                />
                            </>
                        ) : null}
                    </FieldSet>
                    <FieldError></FieldError>
                    <FieldSet legend="Portal Enabled">
                        <Text pb="spacer-2">Creation Date</Text>
                        <Input
                            width={{ base: "90%", lg: "50%" }}
                            type="text"
                            id="portalEnabled"
                            value={formatDateTimeDisplay(store.createdAt)}
                            isReadOnly
                        />
                    </FieldSet>
                    <FieldError></FieldError>
                    <FieldSet legend="Order Sync">
                        <VStack justify={"start"} align={"flex-start"} pl={0}>
                            <Checkbox
                                py={"spacer-2"}
                                spacing="4"
                                size="md"
                                id="orderSyncEnabled"
                                {...register("orderSyncEnabled")}
                            >
                                Order Sync Enabled
                            </Checkbox>
                            <Text pt={"spacer-2"}>Order Sync Cursor</Text>
                            <Input
                                id="orderSyncCursor"
                                width={{ base: "90%", lg: "50%" }}
                                size="md"
                                type="text"
                                {...register("orderSyncCursor")}
                                isDisabled={!store.edgeEnabled}
                            />
                        </VStack>
                    </FieldSet>
                    <FieldError></FieldError>
                    <FieldSet legend="Edge Delivery Engine">
                        <VStack justify={"start"} align={"flex-start"} pl={0}>
                            <Checkbox
                                py={"spacer-2"}
                                spacing="4"
                                size="md"
                                id="edgeEnabled"
                                isReadOnly
                                isChecked={store.edgeEnabled}
                                style={{ cursor: "default" }}
                            >
                                Edge Enabled
                            </Checkbox>

                            <Checkbox
                                pl={"spacer-8"}
                                spacing="4"
                                size="md"
                                id="edgeMemberEnabled"
                                {...register("edgeMemberEnabled")}
                            >
                                Caching for logged in users
                            </Checkbox>
                        </VStack>
                        <Checkbox
                            pt={"spacer-4"}
                            spacing="4"
                            size="md"
                            id="localCacheEnabled"
                            {...register("localCacheEnabled")}
                        >
                            Local Caching
                        </Checkbox>
                        <Text pt={"spacer-4"} pb={"spacer-2"}>
                            Currently Deployed Worker Branch: {store?.workerBranch}
                        </Text>
                        <Text pt={"spacer-4"} pb={"spacer-2"}>
                            Onboard Date
                        </Text>
                        <Input
                            id="edgeEnabledAt"
                            width={{ base: "90%", lg: "50%" }}
                            size="md"
                            type="text"
                            py={"spacer-4"}
                            isReadOnly
                            value={formatDateTimeDisplay(store.edgeEnabledAt) ?? ""}
                        />
                    </FieldSet>
                    <FieldError>{errors.edgeEnabledAt?.message}</FieldError>

                    <FieldSet legend="A/B Testing">
                        <Checkbox
                            spacing={4}
                            size="md"
                            id="personalizationEnabled"
                            py={"spacer-2"}
                            isChecked={store.personalizationEnabled}
                            isReadOnly
                            style={{ cursor: "default" }}
                        >
                            Personalization Enabled
                        </Checkbox>
                        <Text py={"spacer-2"}>Speed Test Start</Text>
                        <Input
                            id="speedABTestStartedAt"
                            width={{ base: "90%", lg: "50%" }}
                            size="md"
                            type="text"
                            isReadOnly
                            value={formatDateTimeDisplay(store.speedABTestStartedAt) ?? ""}
                        />
                        <Text py={"spacer-6"} pb={"spacer-2"}>
                            Speed Test End
                        </Text>
                        <Input
                            id="speedABTestEndedAt"
                            width={{ base: "90%", lg: "50%" }}
                            size="md"
                            type="text"
                            isReadOnly
                            value={formatDateTimeDisplay(store.speedABTestEndedAt) ?? ""}
                        />
                    </FieldSet>
                    <FieldError>{errors.personalizationEnabledAt?.message}</FieldError>

                    <FieldSet legend="Crawler Optimization">
                        <Checkbox
                            spacing={4}
                            size="md"
                            id="staticSiteEnabled"
                            py={"spacer-2"}
                            style={{ cursor: "default" }}
                            {...register("staticSiteEnabled")}
                        >
                            Optimization Enabled
                        </Checkbox>
                        <Text py={"spacer-2"}>Onboard Date</Text>

                        <Input
                            id="staticSiteEnabledAt"
                            width={{ base: "90%", lg: "50%" }}
                            size="md"
                            type="test"
                            py={"spacer-4"}
                            isReadOnly
                            value={formatDateTimeDisplay(store.staticSiteEnabledAt)}
                            {...register("staticSiteEnabledAt")}
                        />
                    </FieldSet>
                    <FieldError>{errors.staticSiteEnabledAt?.message}</FieldError>
                    <FieldSet legend="Log Level">
                        <Checkbox
                            spacing={4}
                            size="md"
                            id="logLevel"
                            onChange={handleLogLevelChange}
                            isChecked={watchLogLevel === "debug"}
                            py={"spacer-2"}
                        >
                            Debug Mode Enabled
                        </Checkbox>
                    </FieldSet>
                    <FieldError></FieldError>
                    <FieldSet legend="UTM Parameters">
                        <Checkbox
                            spacing={4}
                            size="md"
                            id="ignoreUtmParameters"
                            onChange={handleUtmParameterChange}
                            isChecked={watchUtmParameters === true}
                            py={"spacer-2"}
                        >
                            Ignored in cache
                        </Checkbox>
                    </FieldSet>
                    <FieldError></FieldError>
                    <FieldSet legend="Web Vitals Analytics">
                        <Checkbox
                            spacing={4}
                            size="md"
                            id="internalRumEventsEnabled"
                            onChange={handleWebVitalsAnalyticsChange}
                            isChecked={watchInternalRumEventsEnabled === true}
                            py={"spacer-2"}
                        >
                            Enable Web Vitals Analytics
                        </Checkbox>
                    </FieldSet>
                    <FieldError></FieldError>
                    <FieldSet legend="Currency">
                        <Label htmlFor="currencyDefault">Default</Label>
                        <Input
                            placeholder="Currency Default 'USD'"
                            type="text"
                            id="currencyDefault"
                            {...register("currencyDefault")}
                            width="100%"
                        />
                        <FieldError></FieldError>

                        <Label htmlFor="currencyMap">Map</Label>

                        <InputJson
                            {...{
                                name: "currencyMap",
                                formSettings: {
                                    register,
                                    setValue,
                                    errors,
                                    watch,
                                },
                            }}
                        ></InputJson>
                    </FieldSet>
                    <Flex pb="spacer-4">
                        <Stack>
                            <Button
                                w="8rem"
                                onClick={() => {
                                    if (!store.zoneId) {
                                        setDnsSetup((prev) => ({
                                            ...prev,
                                            errorMessage: "Zone ID is required to setup DNS",
                                        }));
                                        return;
                                    }

                                    if (!store.customerUrl) {
                                        setDnsSetup((prev) => ({
                                            ...prev,
                                            errorMessage: "Customer URL is required to setup DNS",
                                        }));
                                        return;
                                    }

                                    if (store.mode !== StoreModes.DnsSetup) {
                                        setDnsSetup((prev) => ({
                                            ...prev,
                                            errorMessage: "Store must be in DNS Setup mode to setup DNS",
                                        }));
                                        return;
                                    }
                                    setDnsSetup((prev) => ({ ...prev, isOpen: true, errorMessage: "" }));
                                }}
                            >
                                DNS Setup
                            </Button>
                            <FieldError>{dnsSetup.errorMessage}</FieldError>
                        </Stack>
                        <Spacer />
                        <Button
                            variant="outline"
                            onClick={onOpen}
                            colorScheme="button-secondary"
                            mr="spacer-2"
                            type="button"
                        >
                            Delete
                        </Button>
                        <Button colorScheme={"button-primary"} ml="auto" type="submit">
                            Submit
                        </Button>
                    </Flex>
                </form>
            </FormControl>
            <DeleteStoreAlertDialog
                isOpen={isOpen}
                close={onClose}
                storeData={store}
                redirectRoute={withDeleteStoreConfirmationModal(ROUTES.LIST_STORES, store)}
            />
            {dnsSetup.isOpen ? (
                <DnsSetup store={store} onClose={() => setDnsSetup((prev) => ({ ...prev, isOpen: false }))} />
            ) : null}
        </PageContainer>
    );
};

const buildDefaultValues = (store: Store) => ({
    name: store.name,
    customerUrl: store.customerUrl,
    ecomUrl: store.ecomUrl,
    currencyDefault: store.currencyDefault === null ? "" : store.currencyDefault,
    currencyMap: store.currencyMap === null ? JSON.stringify({}) : JSON.stringify(store.currencyMap, null, 4),
    orderSyncEnabled: store.orderSyncEnabled,
    orderSyncCursor: store.orderSyncCursor === null ? "" : store.orderSyncCursor,
    edgeMemberEnabled: store.edgeMemberEnabled,
    zoneId: store.zoneId === null ? "" : store.zoneId,
    countryDefault: store.countryDefault === null ? "" : store.countryDefault,
    logLevel: store.logLevel,
    ignoreUtmParameters: store.ignoreUtmParameters,
    staticSiteEnabled: store.staticSiteEnabled,
    internalRumEventsEnabled: store.internalRumEventsEnabled,
    localCacheEnabled: store.localCacheEnabled,
});
