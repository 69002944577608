import { ROUTES } from "../Routes";
import { Layout } from "../layouts/Layout";
import {
    WelcomeToNostraPage,
    InitialTips,
    EdgeDeliveryEnginePage,
    CrawlerOptimizationPage,
    ABTestingPage,
    TestYourSiteWithoutNostraPage,
    WhenAndWhyToPurgeCachePage,
    StaticVsDynamicContentPage,
    Caching101Page,
    EdgeDelivery101Page,
    TourTheNostraPortal,
    WhenToContactNostraPage,
    ClearCacheResource,
    TurnNostraOnOffResource,
    ManageExperimentsResource,
    ManageUsersResource,
    ManageOrganizationResource,
    ManageAllOrganizationsResource,
    ApiDocumentationPage,
    CacheSettingsResource,
    URLPathsPage,
    ApiEntireSiteDoc,
    ApiSinglePageDoc,
    ApiGenerateTokenDoc,
    InstalledAppsResource,
    SingleAppPageResource,
    UnderstandingTestScoresDoc,
    RoiOverview,
    TraditionalMetrics,
    KeyRevenueMetric,
    CustomerJourneyMetrics,
    WebVitalsMetrics,
    SearchEngineRankingMetrics,
    CrawlerOptimizationMetrics,
    AdvertisingMetrics,
    CruxReportDocumentation,
    LighthouseDocumentation,
    PageSpeedInsightsDocumentation,
    ShopifyMetricsDocumentation,
    WhatNostraReadsFromShopify,
    ShopifyLoadingSpeedDoc,
    ReportsOverview,
    CacheHitRatioResource,
    PercentilesDoc,
    StatisticalSignificanceDoc,
    SpeedReportsDoc,
    CruxReportsDoc,
    AutomatedCacheClearings,
    LocalCachingDocumentation,
} from "../pages";

export const documentationRoutes = [
    {
        path: "/",
        element: <Layout />,
        children: [
            { path: ROUTES.DOCUMENTATION, element: <WelcomeToNostraPage /> },
            { path: ROUTES.DOCUMENTATION_TOUR_THE_NOSTRA_PORTAL, element: <TourTheNostraPortal /> },
            { path: ROUTES.DOCUMENTATION_INITIAL_TIPS, element: <InitialTips /> },
            { path: ROUTES.DOCUMENTATION_WHEN_TO_CONTACT_NOSTRA, element: <WhenToContactNostraPage /> },
            { path: ROUTES.DOCUMENTATION_CLEAR_CACHE, element: <ClearCacheResource /> },
            { path: ROUTES.DOCUMENTATION_TURN_OFF_ON, element: <TurnNostraOnOffResource /> },
            { path: ROUTES.DOCUMENTATION_MANAGE_EXPERIMENTS, element: <ManageExperimentsResource /> },
            { path: ROUTES.DOCUMENTATION_MANAGE_USERS, element: <ManageUsersResource /> },
            { path: ROUTES.DOCUMENTATION_MANAGE_ORGANIZATION, element: <ManageOrganizationResource /> },
            { path: ROUTES.DOCUMENTATION_MANAGE_ALL_ORGANIZATIONS, element: <ManageAllOrganizationsResource /> },
            { path: ROUTES.DOCUMENTATION_EDGE_DELIVERY_ENGINE, element: <EdgeDeliveryEnginePage /> },
            { path: ROUTES.DOCUMENTATION_CRAWLER_OPTIMIZATION, element: <CrawlerOptimizationPage /> },
            { path: ROUTES.DOCUMENTATION_AB_TESTING, element: <ABTestingPage /> },
            { path: ROUTES.DOCUMENTATION_API, element: <ApiDocumentationPage /> },
            { path: ROUTES.DOCUMENTATION_API_ENTIRE_SITE, element: <ApiEntireSiteDoc /> },
            { path: ROUTES.DOCUMENTATION_API_SINGLE_PAGE, element: <ApiSinglePageDoc /> },
            { path: ROUTES.DOCUMENTATION_API_GENERATE_TOKEN, element: <ApiGenerateTokenDoc /> },
            { path: ROUTES.DOCUMENTATION_METRICS_ROI, element: <RoiOverview /> },
            { path: ROUTES.DOCUMENTATION_METRICS_TRADITIONAL, element: <TraditionalMetrics /> },
            { path: ROUTES.DOCUMENTATION_METRICS_KEY_REVENUE, element: <KeyRevenueMetric /> },
            { path: ROUTES.DOCUMENTATION_METRICS_CUSTOMER_JOURNEY, element: <CustomerJourneyMetrics /> },
            { path: ROUTES.DOCUMENTATION_METRICS_WEB_VITALS, element: <WebVitalsMetrics /> },
            { path: ROUTES.DOCUMENTATION_METRICS_SEARCH_ENGINE_RANKING, element: <SearchEngineRankingMetrics /> },
            { path: ROUTES.DOCUMENTATION_METRICS_CRAWLER_OPTIMIZATION, element: <CrawlerOptimizationMetrics /> },
            { path: ROUTES.DOCUMENTATION_METRICS_ADVERTISING, element: <AdvertisingMetrics /> },
            { path: ROUTES.DOCUMENTATION_THIRD_PARTY_DEFAULTS, element: <UnderstandingTestScoresDoc /> },
            { path: ROUTES.DOCUMENTATION_THIRD_PARTY_CRUX, element: <CruxReportDocumentation /> },
            { path: ROUTES.DOCUMENTATION_THIRD_PARTY_LIGHTHOUSE, element: <LighthouseDocumentation /> },
            { path: ROUTES.DOCUMENTATION_THIRD_PARTY_PAGESPEED_INSIGHTS, element: <PageSpeedInsightsDocumentation /> },
            { path: ROUTES.DOCUMENTATION_SHOPIFY, element: <ShopifyMetricsDocumentation /> },
            { path: ROUTES.DOCUMENTATION_SHOPIFY_LOADING_SPEED, element: <ShopifyLoadingSpeedDoc /> },
            { path: ROUTES.DOCUMENTATION_TEST_YOUR_SITE_WITHOUT_NOSTRA, element: <TestYourSiteWithoutNostraPage /> },
            { path: ROUTES.DOCUMENTATION_WHEN_AND_WHY_TO_PURGE_CACHE, element: <WhenAndWhyToPurgeCachePage /> },
            { path: ROUTES.DOCUMENTATION_AUTOMATED_CACHE_CLEARING, element: <AutomatedCacheClearings /> },
            { path: ROUTES.DOCUMENTATION_CACHE_SETTINGS, element: <CacheSettingsResource /> },
            { path: ROUTES.DOCUMENTATION_CACHE_HIT_RATIO, element: <CacheHitRatioResource /> },
            { path: ROUTES.DOCUMENTATION_URL_PATHS, element: <URLPathsPage /> },
            { path: ROUTES.DOCUMENTATION_STATIC_VS_DYNAMIC_CONTENT, element: <StaticVsDynamicContentPage /> },
            { path: ROUTES.DOCUMENTATION_CACHING_101, element: <Caching101Page /> },
            { path: ROUTES.DOCUMENTATION_EDGE_DELIVERY_101, element: <EdgeDelivery101Page /> },
            { path: ROUTES.DOCUMENTATION_INSTALLED_APPS, element: <InstalledAppsResource /> },
            { path: ROUTES.DOCUMENTATION_SINGLE_APP, element: <SingleAppPageResource /> },
            { path: ROUTES.DOCUMENTATION_SHOPIFY_PII, element: <WhatNostraReadsFromShopify /> },
            { path: ROUTES.DOCUMENTATION_REPORTS, element: <ReportsOverview /> },
            { path: ROUTES.DOCUMENTATION_REPORTS_SPEED, element: <SpeedReportsDoc /> },
            { path: ROUTES.DOCUMENTATION_REPORTS_CRUX, element: <CruxReportsDoc /> },
            { path: ROUTES.DOCUMENTATION_REPORTS_PERCENTILES, element: <PercentilesDoc /> },
            { path: ROUTES.DOCUMENTATION_REPORTS_STATISTICAL_SIGNIFICANCE, element: <StatisticalSignificanceDoc /> },
            { path: ROUTES.DOCUMENTATION_LOCAL_CACHING, element: <LocalCachingDocumentation /> },
        ],
    },
];
