import { PageContainer } from "../../PageContainer";
import { Text, Box, UnorderedList, ListItem } from "@chakra-ui/react";
import { Header, SubHeader, Link, DocumentationImage } from "../../../components";
import { ROUTES } from "../../../Routes";
import { DocsBreadcrumbComponent } from "../DocsBreadcrumbComponent";
import { BRAND_NAME, MAIN_PRODUCT } from "../../../config/BRAND_NAME";

export const LocalCachingDocumentation = () => {
    return (
        <PageContainer>
            <DocsBreadcrumbComponent
                menuItem1={"Upgrades"}
                menuItem1Link={ROUTES.DOCUMENTATION_LOCAL_CACHING}
                menuItem2={`Local Caching`}
            />
            <Header pb={"spacer-8"}>Local Caching</Header>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Speed and efficiency are crucial in providing an exceptional user experience across the eCommerce space.
                One strategy that web developers employ to enhance website performance is local caching, an optimization{" "}
                {BRAND_NAME} can implement across your store and user base—without any additional configuration from
                your team.{" "}
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Most popular eCommerce platforms utilize modern content delivery networks (CDNs), such as the Cloudflare
                CDN, to provide CDN caching for static assets, including images and stylesheets. {BRAND_NAME} takes this
                form of caching even further, enabling smart caching mechanisms for static and dynamic content.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The good news is that an additional caching strategy, local caching, can be used across your store for
                even better load times.{" "}
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Let's explore local caching, its benefits and drawbacks, and when it's best to utilize this technique in
                addition to {BRAND_NAME}'s advanced {MAIN_PRODUCT}.{" "}
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                What Is Local Caching?
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Before exploring local caching, remember the basics. At its highest level, caching is the process of
                storing copies of files in a temporary location for quicker access.{" "}
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Local caching, particularly in the context of eCommerce, revolves around each user's browser. Whenever a
                webpage is first loaded, the browser downloads varying amounts of data, such as images, scripts, and
                stylesheets.{" "}
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Browsers with local caching enabled store copies of the page's data to improve future loading times from
                the user's device. Browsers cache this content within the individual user's device storage.
            </Text>
            <Text textStyle={"text-body-bold"} pb={"spacer-6"}>
                With Local Caching
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-12/caa09197-4481-4704-9efe-699abcd38bf8/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=3502,1336&force_format=jpeg&q=100&width=1120.0"
                alt="With local caching diagram"
                loading="eager"
            />
            <Text textStyle={"text-body-bold"} pb={"spacer-6"}>
                Without Local Caching
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-12-12/0cd54cb7-ac36-4ab8-b039-29f643d241c6/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=3470,1333&force_format=jpeg&q=100&width=1120.0"
                alt="Without local caching diagram"
                loading="lazy"
            />
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Benefits Of Local Caching
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Local caching on {BRAND_NAME} provides a significant performance advantage by eliminating the need to
                download the same pages multiple times during a single browsing session. This becomes particularly
                valuable in numerous everyday scenarios where customers naturally navigate back and forth between pages
                during their shopping experience. These common scenarios exemplify when {BRAND_NAME}'s local caching
                would deliver substantial performance benefits:
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    <strong>Clicking the back button</strong> to return to previously viewed pages after exploring
                    product details or browsing different collections
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Clicking your logo to go to the homepage</strong> while navigating through various sections
                    of your online store
                </ListItem>
                <ListItem pb={"spacer-6"}>
                    <strong>Comparing product prices</strong> by moving between different product pages to evaluate
                    options
                </ListItem>
            </UnorderedList>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                By caching content locally, the need to continuously download data from {BRAND_NAME}'s {MAIN_PRODUCT}{" "}
                between pages is reduced, which accelerates page load times in each of these instances.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                This strategic enhancement to {BRAND_NAME}'s {MAIN_PRODUCT} not only optimizes technical performance but
                also provides a more fluid and responsive browsing experience. The result is enhanced customer
                satisfaction through seamless navigation and reduced waiting times.
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Drawbacks Of Local Caching{" "}
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                While local caching offers many benefits, there are some drawbacks to consider:
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    <strong>Already seen pages will not update in that session:</strong> If a customer is browsing a
                    product page that later goes on sale in that session, for example, they will not see that new sale
                    price until they start a new session.
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Testing considerations:</strong> You will need to use an incognito browser to test new or
                    updated content as your current browser session will not reflect those new updates in the local
                    cache.
                </ListItem>
                <ListItem pb={"spacer-6"}>
                    <strong>Temporary solution as a standalone optimization:</strong> Local caching is not a long-term
                    fix on its own. If a user ends a session by closing their browser, the local cache will reset. That
                    is where more robust caching solutions like {BRAND_NAME}'s {MAIN_PRODUCT} are needed to deliver more
                    resilient, global optimizations that local caching can accelerate.{" "}
                </ListItem>
            </UnorderedList>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                When We Do Not Recommend {BRAND_NAME}'s Local Caching Solution
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                We do not recommend you apply local caching with {BRAND_NAME} if you:
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    <strong>Sell wholesale products on the same URLs as your standard DTC business:</strong> This setup
                    requires real-time price updates and inventory management that local caching could interfere with.
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Deploy changes to your website more often than every 30 minutes:</strong> Frequent updates
                    to your site content would be delayed or potentially missed by users due to local caching.
                </ListItem>
                <ListItem pb={"spacer-6"}>
                    <strong>
                        Personalize your homepage, collection pages, or product pages when a user is logged in:
                    </strong>{" "}
                    Local caching could prevent the proper display of user-specific content and customized experiences.
                </ListItem>
            </UnorderedList>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Next Steps
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Implementing {BRAND_NAME}'s local caching can significantly improve website performance by enhancing
                speed and efficiency while creating a better user experience.{" "}
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                However, it's important to be aware of local caching's limitations and to avoid its use in scenarios
                where time-sensitive, dynamic content is critical. By understanding the balance of when local caching is
                beneficial, you can create more efficient and user-friendly websites.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                <Link
                    textDecoration="underline"
                    to={"https://nostra-corporate.webflow.io/contact-nostra-support"}
                    target="new"
                >
                    Contact our support team
                </Link>{" "}
                if you'd like to enable {BRAND_NAME}'s local caching to further enhance your site's performance.{" "}
            </Text>
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </PageContainer>
    );
};
